import {Moment} from 'moment-timezone'

/**
 * function to round datetime to 15 min interval end
 * starting from the beginning of an hour
 * Eg. "2023-08-15T02:05:00Z" to "2023-08-15T02:15:00Z"
 * Eg. "2023-08-15T02:16:00Z" to "2023-08-15T02:30:00Z"
 */
export const roundTo15MinIntervalEnd = (datetime: Moment) => {
  const minutes = datetime.minutes()
  if (minutes >= 45) {
    return datetime.clone().startOf('hour').add(60, 'minutes')
  }
  if (minutes >= 30) {
    return datetime.clone().startOf('hour').add(45, 'minutes')
  }
  if (minutes >= 15) {
    return datetime.clone().startOf('hour').add(30, 'minutes')
  }
  return datetime.clone().startOf('hour').add(15, 'minutes')
}
